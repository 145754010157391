import React, {useEffect, useState} from "react";
import Price from "./components/price";
import "./style.css";
import cfg from "../../config/Config";
import CustomLoader from "../../components/common/custom-loader";

const Prices = () => {
    const [prices, setPrices] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const url = cfg.backendUrl + "price";
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                setPrices(response);
                setIsLoaded(true);
            });
    }, []);

    return (
        <div className="prices-page">
            {isLoaded ?
                <div className="prices-container">
                    <div className="prices-header">Наши цены</div>
                    <div className="prices-content">
                        {prices.map((price) => <Price key={price.name} data={price}/>)}
                    </div>
                </div> : <CustomLoader/>}
        </div>
    )
}

export default Prices;