import React from 'react';

export default function LayoutCol1({children}) {
  return (
    <main className="-mt-24 pb-8 sm:-mt-20 min-h-screen">
      <div className="mx-auto px-4 sm:px-8 lg:px-12 pt-56">
        <div className="rounded-lg bg-white overflow-hidden shadow">
          <div className="p-6">
            {children}
          </div>
        </div>
      </div>
    </main>
  )
}