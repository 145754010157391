import React from "react";
import './style.css'

const CustomButton = (props) => {
    const classNameSize = props.size === "small" ?
        "custom-button-small" :
        props.size === "modal" ?
            "custom-button-modal" :
            "custom-button-large"

    return (
        <button style={props.style} onClick={props.onClick} ref={props.btnRef} className={"custom-button " + classNameSize}>
            {props.children}
        </button>
    )
}

export default CustomButton;