import React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {shoppingCartAtom, shoppingCartSelector} from "../../recoil/atoms/shoppingCart";
import {Link} from "react-router-dom";
import {TrashIcon} from "@heroicons/react/solid";
import cfg from "../../../config/Config";

export default function Order() {
  const {shoppingCart, totalPrice} = useRecoilValue(shoppingCartSelector);

  const [, setShoppingCart] = useRecoilState(shoppingCartAtom);

  const changeCount = (e, id) => {
    const count = +e.target.value
    if (count < 1) {
      return;
    }

    const index = shoppingCart.findIndex(item => item.id === id)
    if (index === -1) {
      return
    }
    const el = shoppingCart[index]
    setShoppingCart([...shoppingCart.slice(0, index), {
      ...el,
      count,
    }, ...shoppingCart.slice(index + 1)])
  }

  const removeProduct = (id) => {
    const index = shoppingCart.findIndex(item => item.id === id)
    setShoppingCart([...shoppingCart.slice(0, index), ...shoppingCart.slice(index + 1)])
  }

  return (
    <div className="mt-8">
      <div className="flow-root">
        <ul className="-my-6 divide-y divide-gray-200">
          {shoppingCart.map(item => (
            <li key={item.id} className="flex py-6">
              <div className="h-32 w-32 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                <img
                  src={`${cfg.backendImageUrl}product_img/${item.product.images[0]?.url || 'noimg.jpg'}`}
                  alt={item.product.name}
                  className="h-full w-full object-cover object-center"
                />
              </div>

              <div className="ml-4 flex flex-1 flex-col">
                <div>
                  <div className="flex justify-between text-base font-medium text-gray-900">
                    <h3>
                      <Link to={`/product/name/${item.name_in_url}`}> {item.product.name} </Link>
                    </h3>
                    {/*<p>*/}
                    {/*  {JSON.stringify(item)}*/}
                    {/*</p>*/}
                    <p>
                      <input
                        type="number"
                        name="first-name"
                        id="first-name"
                        value={item.count}
                        min={1}
                        step={1}
                        onChange={(e) => changeCount(e, item.id)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </p>
                    <p className="ml-4">€{item.product.price} * {item.count} = €{item.count * item.product.price}</p>
                  </div>
                </div>
                <div className="flex flex-1 items-end justify-between text-sm">
                  <div className="text-gray-800 font-bold" dangerouslySetInnerHTML={{__html: item.product.title}}/>

                  <div className="flex">
                    <button
                      type="button"
                      className="font-medium text-indigo-600 hover:text-indigo-500 w-12 h-12"
                      onClick={() => removeProduct(item.id)}
                    >
                      <TrashIcon/>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}

          <li className="py-6 text-right text-xl font-bold">
            Итого: €{totalPrice}
          </li>
        </ul>
      </div>
    </div>
  )
}
