import {atom, selector} from "recoil";

const localStorageEffect = key => ({setSelf, onSet}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};

export const shoppingCartAtom = atom({
  key: "shoppingCartState",
  default: [],
  effects: [
    localStorageEffect('shoppingCart'),
  ]
});


export const shoppingCartSelector = selector({
  key: "shoppingCartStateSelector",
  get: ({get}) => {
    const list = get(shoppingCartAtom)

    return {
      shoppingCart: list,
      totalCount: list.length,
      totalPrice: list.reduce((acc, el) => {
        return acc + el.count * el.product.price
      }, 0)
    }
  }
});