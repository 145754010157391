import * as config from '12factor-config';

// export const backendUrl = "https://motorcar-tuning.com.ua/api/";
// export const backendImageUrl = "https://motorcar-tuning.com.ua/images/";

// export const backendUrl = "http://localhost:8888/api/";
// export const backendImageUrl = "http://localhost:8080/api/image/";

const conf = config({
  backendUrl: {
    env: 'REACT_APP_BACK_URL',
    type: 'string',
    default: 'https://motorcar-tuning.com.ua/api/',
    // default: 'http://localhost:8888/api/,
    required: true,
  },
  backendImageUrl: {
    env: 'REACT_APP_BACK_IMAGE_URL',
    type: 'string',
    default: 'https://motorcar-tuning.com.ua/images/',
    // default: 'http://localhost:8080/api/images,
    required: true,
  },
});

export default conf;