import React, {useEffect, useState} from "react";
import "./style.css"
import Equipment from "./equipment";
import cfg from "../../config/Config";
import CustomLoader from "../../components/common/custom-loader";

const Equipments = () => {
    const [equipments, setEquipments] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const url = cfg.backendUrl + "equipment";
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                setEquipments(response);
                setIsLoaded(true);
            });
    }, []);


    return (
        <div className="equipments-page">
            {isLoaded ?
                <div className="equipments-container">
                    <div className="equipments-block">
                        <div className="equipments">
                            <div className="equipments-content">
                                {equipments.map((equipment) => <Equipment key={equipment.name_in_url} data={equipment}/>)}
                            </div>
                        </div>
                        <div className="equipments-filter">
                            <div className="equipments-filter-header">Фильтр</div>
                        </div>
                    </div>
                </div> : <CustomLoader/>}
        </div>
    )
}


export default Equipments;