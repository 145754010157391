import React, {useState} from "react";
import "./style.css"
import CustomButton from "../../../components/simple/custom-button";
import cfg from "../../../config/Config";
import { useHistory } from "react-router-dom";
import RequestModal from "../../../components/common/request-modal";


const Equipment = (props) => {
    const history = useHistory();
    const equipmentImage = cfg.backendImageUrl + "equipment/" + props.data.image;

    const onClickLink = () => {
        history.push(`equipment/${props.data.name_in_url}`);
    }

    const [openRequestModal, setOpenRequestModal] = useState(false);

    const closeModalHandle = () => {
        setOpenRequestModal(false);
    }

    return (
        <div className="equipment-component">
            <div className="equipment-component-container">
                <div className="equipment-component-info">
                    <div>
                        <div className="equipment-component-header-container">
                            <div className="equipment-component-header">{props.data.name}</div>
                            <div className="equipment-component-price">{props.data.price}</div>
                        </div>
                        <div className="equipment-component-main-article">
                            {props.data.main_article}
                        </div>

                    </div>
                    <div>
                        <div onClick={onClickLink} className="equipment-component-direct-link">
                            Подробнее
                        </div>
                        <CustomButton onClick={() => setOpenRequestModal(true)}>Оставить заявку</CustomButton>
                        <RequestModal type={"equipment"} name={props.data.name} closeModalHandle={closeModalHandle} open={openRequestModal}/>
                    </div>
                </div>
                <div className="equipment-component-image-container">
                    <img alt="equip" className="equipment-component-image" src={equipmentImage} />
                </div>
            </div>
        </div>
    )

}

export default Equipment