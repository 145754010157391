import React from "react";
import {useRecoilValue} from "recoil";
import {shoppingCartSelector} from "../recoil/atoms/shoppingCart";
import {ShoppingCartIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";

export default function ShoppingCartHeader() {
  const {totalCount} = useRecoilValue(shoppingCartSelector);

  return (
    // <Link to="/order" className="!ml-auto pt-1 h-6 w-16 text-white text-center cursor-pointer">
    <Link to="/order" className="!ml-auto -mt-4 h-6 w-16 mr-4 text-white text-center cursor-pointer">
      <ShoppingCartIcon className="h-8 w-8 ml-3"/>
      {totalCount === 0 ? '' : <span className="text-xs">Товаров: {totalCount}</span>}
    </Link>
  )
}