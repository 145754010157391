import React, {useEffect, useState} from "react";
import "./style.css";
import CustomButton from "../../components/simple/custom-button";
import cfg from "../../config/Config";
import {useParams} from "react-router-dom";
import CustomLoader from "../../components/common/custom-loader";
import RequestModal from "../../components/common/request-modal";

const Equipment = () => {
    let {name_in_url} = useParams();
    const [equipment, setEquipment] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const [openRequestModal, setOpenRequestModal] = useState(false);
    const closeModalHandle = () => {
        setOpenRequestModal(false);
    }

    useEffect(() => {
        const url = cfg.backendUrl + "equipment/" + name_in_url;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                setEquipment(response);
                setIsLoaded(true);
            });
    }, [name_in_url]);

    return (
        <div className="equipment-page">
            {isLoaded ?
                <div className="equipment-container">
                    <div className="equipment-main-info-container">
                        <img src={cfg.backendImageUrl + "equipment/" + equipment.image} alt="equipment"
                             className="equipment-img"/>
                        <div className="equipment-main-info">
                            <div className="equipment-header">{equipment.name}</div>
                            <div className="equipment-main-article">
                                {equipment.main_article}
                            </div>
                            <CustomButton onClick={() => setOpenRequestModal(true)}>Оставить заявку</CustomButton>
                            <RequestModal type={"equipment"} name_in_url={name_in_url} closeModalHandle={closeModalHandle} open={openRequestModal}/>
                        </div>
                    </div>
                    <div className="equipment-article">
                        <div dangerouslySetInnerHTML={{__html: equipment.full_article}}/>
                    </div>
                    <div className="equipment-characteristic-container">
                        <div className="equipment-characteristic-header">Другие характеристики</div>
                        <div className="equipment-characteristics">
                            {
                                equipment.characteristics.map(characteristic => {
                                    return (<div key={characteristic} className="equipment-characteristic">
                                        <div className="characteristic-prefix">—</div>
                                        <div>{characteristic}</div>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div> : <CustomLoader/>}
        </div>
    )
}

export default Equipment;