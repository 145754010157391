import React, {useEffect, useState} from "react";
import "./style.css";
import cfg from "../../config/Config";
import CustomLoader from "../../components/common/custom-loader";

const FAQ = () => {
    const [faq, setFaq] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const url = cfg.backendUrl + "faq";
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                setFaq(response);
                setIsLoaded(true);
            });
    }, []);

    return (
        <div className="faq-page">
            {isLoaded ?
            <div className="faq-container">
                <div className="faq-header">FAQ</div>
                <div className="faq-article" dangerouslySetInnerHTML={{__html: faq.article}}/>
            </div> : <CustomLoader/>}
        </div>
    )
}

export default FAQ;