import React, {useEffect, useState} from "react";
import "./style.css";
import cfg from "../../config/Config";
import CustomLoader from "../../components/common/custom-loader";

const Gallery = () => {
  const [models, setModels] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showFull, setShowFull] = useState('');

  useEffect(() => {
    const url = cfg.backendUrl + "gallery";
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(response => {
        setModels(response);
        setIsLoaded(true);
      });
  }, []);

  const onClickBlogHandle = (imageFull) => {
    setShowFull(imageFull)
  }

  return (
    <div className="blog">
      {isLoaded ?
        <div className="blog-container">
          <div className="blog-blogs-container">
            {models.map((model) => {
                const image = cfg.backendImageUrl + "gallery/" + model.image;
                const imageFull = cfg.backendImageUrl + "gallery/full/" + model.image;
                return <div className="blog-blog-field" key={model.id}>
                  <img alt={"img"} onClick={() => onClickBlogHandle(imageFull)}
                       className="blog-picture-container"
                       src={image}>
                  </img>
                </div>
              }
            )}
          </div>
        </div> : <CustomLoader/>}

      {showFull && <div className="overlay">
        <div className="popup">
          <span className="close" onClick={() => setShowFull(null)}>&times;</span>
          <div className="img-content">
            <img src={showFull} alt=""/>
          </div>
        </div>
      </div>
      }
    </div>
  )
}

export default Gallery