import React from "react";

import {
  BrowserRouter as Router, Route, Switch,
} from "react-router-dom";

import './index.css'
import RouteList from "./RouteList";
import Header from "../components/common/header";
import Footer from "../components/common/footer";

// const ScrollToTop = () => {
//   const {pathname} = useLocation();
//
//   useEffect(() => {
//     window.history.scrollRestoration = 'manual'
//     document.body.scrollTop = 0;
//     document.documentElement.scrollTop = 0
//     window.scrollTo(0, 0);
//   }, [pathname]);
//
//   return null;
// }

// const contacts = [
//   {city: 'Днепр', phone: '+38 (063) 584 31 64', address: 'Береговая, 141'},
//   {city: 'Мукачево', phone: '+38 (066) 735 25 50', address: '-'},
//   {city: 'Полтава', phone: '+38 (066) 883 41 17', address: 'Киевское шосе, 33'},
//   {city: 'Славянск', phone: '+38 (099) 415 58 28', address: 'Почтовая, 44'},
//   {city: 'Харьков', phone: '+38 (097) 073 34 95', address: 'пер. Людмилы Гурченко 3Д'},
// ]

export default function App() {
  return (
    <Router>
      {/*<ScrollToTop/>*/}

      <div className="min-h-full">
        {/*<Header contacts={contacts}/>*/}
        <Header/>

        <Switch>
          {RouteList.map(route =>
            <Route key={route.url} exact path={route.url}>
              {route.component}
            </Route>)}
        </Switch>

        {/*<Footer contacts={contacts}/>*/}
        <Footer/>
      </div>
    </Router>
  )
}