import Main from "../pages/main";
import Blogs from "../pages/blogs";
import Equipments from "../pages/equipments";
import Prices from "../pages/prices";
import Equipment from "../pages/equipment";
import FAQ from "../pages/faq";
import FileService from "../pages/file-service";
import Blog from "../pages/blog";
import Partners from "../pages/partners";
import Gallery from "../pages/gallery";

const RouteList = [
    {
        name: "Главная",
        url: "/",
        component: <Main/>,
        inHeader: true
    },
    {
        name: "Магазин",
        url: "/equipment",
        component: <Equipments/>,
        inHeader: true
    },
    {
        url: "/equipment/:name_in_url",
        component: <Equipment/>
    },
    {
        name: "Файл сервис",
        url: "/file-service",
        component: <FileService/>,
        inHeader: true
    },
    {
        name: "Цены",
        url: "/prices",
        component: <Prices/>,
        inHeader: true
    },
    {
        name: "Блог",
        url: "/blog",
        component: <Blogs/>,
        inHeader: true
    },
    {
        url: "/blog/:name_in_url",
        component: <Blog/>
    },
    {
        name: "FAQ",
        url: "/faq",
        component: <FAQ/>,
        inHeader: true
    },
    {
        name: "Галерея",
        url: "/gallery",
        component: <Gallery/>,
        inHeader: true
    },
    {
        name: "Партнеры",
        url: "/partners",
        component: <Partners/>,
        inHeader: true,
    }
]

export default RouteList;

