import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {shoppingCartAtom} from "../../../recoil/atoms/shoppingCart";
import cfg from "../../../../config/Config";
import CustomLoader from "../../../../components/common/custom-loader";
import {DocumentDownloadIcon} from '@heroicons/react/solid';
import './Product.css'

const baseImage = 'https://motorcar-tuning.com.ua/LOGO-01.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProductByName() {
  const {name_in_url} = useParams();

  const [, setShoppingCart] = useRecoilState(shoppingCartAtom);

  const [model, setModel] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState('description');

  useEffect(() => {
    const url = `${cfg.backendUrl}shop/products/name/${name_in_url}`
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(response => {
        setModel(response);
        setIsLoaded(true);
        setSelectedImage(0);

        document.title = response.seo_title
        document.querySelectorAll('meta[name="description"]')[0].content = response.seo_description;
        document.querySelectorAll('meta[name="keywords"]')[0].content = response.seo_keywords;

        document.querySelectorAll('[property="og:image"]')[0].content = `${cfg.backendImageUrl}product_img/${response.images[0]?.url || 'noimg.jpg'}`;
        document.querySelectorAll('[property="og:image:secure_url"]')[0].content = `${cfg.backendImageUrl}product_img/${response.images[0]?.url || 'noimg.jpg'}`;

        const tabList = []
        tabList.push({
          title: 'Description',
          key: 'description'
        });
        if ((response.packages || []).length > 0) {
          tabList.push({
            title: 'Package content',
            key: 'packages'
          })
        }
        if ((response.downloads || []).length > 0) {
          tabList.push({
            title: 'Download',
            key: 'downloads'
          })
        }
        if (response.how_it_works.length > 0) {
          tabList.push({
            title: 'How it works',
            key: 'how_it_works'
          })
        }

        setTabs(tabList)
        setCurrentTab('description')
      });
    return () => {
      document.title = 'motorcar tuning'
      document.querySelectorAll('meta[name="description"]')[0].content = 'Тюнинг-ателье и команда профессионалов, открываем новые возможности автомобилей. Официальный дистрибьютор компании MAGICMOTORSPORT';

      document.querySelectorAll('[property="og:image"]')[0].content = baseImage;
      document.querySelectorAll('[property="og:image:secure_url"]')[0].content = baseImage;
    }
  }, [name_in_url]);

  if (!isLoaded) {
    return (<CustomLoader/>)
  }

  const scrollToTop = () => {
    window.history.scrollRestoration = 'manual'
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0
    window.scrollTo(0, 0);
  }

  const addToCart = () => {
    setShoppingCart((oldList) => {
      const index = oldList.findIndex(el => el.id === model.id)
      if (index === -1) {
        return [
          ...oldList,
          {
            id: model.id,
            count: 1,
            product: model,
          }
        ]
      }
      const el = oldList[index]
      return [...oldList.slice(0, index), {
        ...el,
        count: el.count + 1,
      }, ...oldList.slice(index + 1)];
    })
    scrollToTop()
  }

  return (
    <div className="bg-white">
      <div className="pt-6">
        <nav aria-label="Breadcrumb">
          <ol className="max-w-2xl mx-auto px-4 flex items-center space-x-2 sm:px-6 lg:max-w-7xl lg:px-8">

            <li>
              <div className="flex items-center">
                <Link to="/" className="mr-2 text-sm font-medium text-gray-900">
                  Main
                </Link>
                <svg
                  width={16}
                  height={20}
                  viewBox="0 0 16 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="w-4 h-5 text-gray-300"
                >
                  <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z"/>
                </svg>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <Link to={`/category/${model.category.id}`} className="mr-2 text-sm font-medium text-gray-900">
                  {model.category.title}
                </Link>
                <svg
                  width={16}
                  height={20}
                  viewBox="0 0 16 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="w-4 h-5 text-gray-300"
                >
                  <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z"/>
                </svg>
              </div>
            </li>
            <li className="text-sm">
              <span aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {model.title}
              </span>
            </li>
          </ol>
        </nav>


        {/* Product info */}
        <div
          className="max-w-2xl mx-auto pt-10 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:pt-16 lg:pb-24 lg:px-8 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">

            <div className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden lg:block">
              <img
                src={`${cfg.backendImageUrl}product_img/${model.images[selectedImage]?.url || 'noimg.jpg'}`}
                alt={model.title}
                className="w-full h-full object-center object-cover"
              />
            </div>

            <div className="grid grid-cols-6 mt-6 gap-x-3">
              {model.images.map((image, i) => (
                <div
                  key={image.id}
                  className="hidden aspect-w-3 aspect-h-2 rounded-lg border-2 border-gray-300 overflow-hidden lg:block">
                  <img
                    src={`${cfg.backendImageUrl}product_img/${image.url}`}
                    alt={model.title}
                    className="w-full h-full object-center object-cover cursor-pointer"
                    onClick={() => setSelectedImage(i)}
                  />
                </div>
              ))}
            </div>

          </div>

          {/* Options */}
          <div className="mt-4 lg:mt-0 lg:row-span-3">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">{model.title}</h1>
            <p className="text-sm my-3 font-bold">Артикул: <span className="font-normal text-gray-500">{model.sku}</span>
            </p>

            <div className="text-base text-gray-900 border-b border-gray-200 pb-7"
                 dangerouslySetInnerHTML={{__html: model.details}}/>

            <p className="text-3xl text-red-800 my-4 font-bold">€{model.price}</p>

            <div className="mt-10">

              <button
                className="mt-10 w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={addToCart}
              >
                Добавить в корзину
              </button>
            </div>
          </div>

          <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-3 lg:pr-8">

            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <span
                      key={tab.key}
                      onClick={() => setCurrentTab(tab.key)}
                      className={classNames(
                        tab.key === currentTab
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                      aria-current={tab.key === currentTab ? 'page' : undefined}
                    >
                      {tab.title}
                    </span>
                  ))}
                </nav>
              </div>
            </div>

            {currentTab === 'description' &&
              <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-3 lg:pr-8"
                   dangerouslySetInnerHTML={{__html: model.description}}/>}
            {currentTab === 'packages' && <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-3 lg:pr-8">

              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                        <tr>
                          <th scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            SKU
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Title
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Amount
                          </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {model.packages.map((pkg) => (
                          <tr key={pkg.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {pkg.sku}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-blue-400">
                              {pkg.packageProduct.is_show
                                ? <Link to={`/product/name/${pkg.packageProduct.name_in_url}`}>{pkg.packageProduct.title}</Link>
                                : pkg.packageProduct.title
                              }
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{pkg.amount}</td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </div>}
            {currentTab === 'downloads' && <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-3 lg:pr-8">

              <ul className="divide-y divide-gray-200">
                {model.downloads.map(({id, url}) => (
                  <li key={id} className="py-4 flex">
                    <DocumentDownloadIcon className="h-8 w-8 rounded-full"/>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900 pt-2">
                        <a href={`${cfg.backendImageUrl}product_dwld/${url}`}>download</a>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>

            </div>}
            {currentTab === 'how_it_works' &&
              <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-3 lg:pr-8"
                   dangerouslySetInnerHTML={{__html: model.how_it_works}}/>}

          </div>
        </div>
      </div>
    </div>
  )
}