const animatedComponents = [];


function isScrolledIntoView(component) {
    const windowBottom = window.scrollY + window.innerHeight;
    const elemBottom = component.component.offsetTop + (component.startTop ? 0 : component.component.offsetHeight);
    return windowBottom - 20 > elemBottom;
}

document.addEventListener("scroll", () => {
    for (let index = 0; index < animatedComponents.length; index++) {
        const component = animatedComponents[index];
        if (!component.component) {
            return;
        }
        if (isScrolledIntoView(component)) {
            component.component.style.setProperty("opacity", "1");
            component.component.classList.add("animate__animated");
            component.component.classList.add(`animate__${component.animation}`);

            if (index > -1) {
                animatedComponents.splice(index, 1);
                index--;
            }
        }
    }
})


export function animate(component, animation, startTop, duration) {
    if (!component) {
        return;
    }

    if (isScrolledIntoView({
        component: component,
        startTop: startTop,
    })) {
        component.style.setProperty("opacity", "1");
        component.classList.add("animate__animated");
        component.classList.add(`animate__${animation}`);
    } else {
        component.style.setProperty("opacity", "0");
        if (duration) {
            component.style.setProperty("--animate-duration", `${duration}s`);
        }
        animatedComponents.push({
            component: component,
            animation: animation,
            startTop: startTop
        })
    }
}