import LayoutCol1 from "./common/layouts/LayoutCol1";
import Main from "../pages/main";
import Shop from "./pages/Shop/Shop";
import Equipment from "../pages/equipment";
import FileService from "../pages/file-service";
import Prices from "../pages/prices";
import Blogs from "../pages/blogs";
import Blog from "../pages/blog";
import FAQ from "../pages/faq";
import Gallery from "../pages/gallery";
import Partners from "../pages/partners";
import Product from "./pages/Shop/Product/Product";
import LayoutCol3L from "./common/layouts/LayoutCol3L";
import Order from "./pages/Shop/Order";
import OrderForm from "./pages/Shop/OrderForm";
import ProductByName from "./pages/Shop/Product/ProductByName";
import LayoutOld from "./common/layouts/LayoutOld";

const RouteList = [
    {
        name: "Главная",
        url: "/",
        component: <LayoutOld><Main/></LayoutOld>,
        inHeader: true
    },
    {
        name: "Магазин",
        url: "/equipment",
        component: <Shop/>,
        inHeader: true
    },
    {
        url: "/category/:id",
        component: <Shop/>,
    },
    {
        url: "/product/:id",
        component: <LayoutCol1><Product/></LayoutCol1>,
    },
    {
        url: "/product/name/:name_in_url",
        component: <LayoutCol1><ProductByName/></LayoutCol1>,
    },
    {
        url: "/order",
        component: <LayoutCol3L left={<Order/>} right={<OrderForm/>}/>
    },
    {
        url: "/equipment/:name_in_url",
        component: <LayoutCol1><Equipment/></LayoutCol1>
    },
    {
        name: "Файл сервис",
        url: "/file-service",
        component: <LayoutOld><FileService/></LayoutOld>,
        inHeader: true
    },
    {
        name: "Цены",
        url: "/prices",
        component: <LayoutOld><Prices/></LayoutOld>,
        inHeader: true
    },
    {
        name: "Блог",
        url: "/blog",
        component: <LayoutOld><Blogs/></LayoutOld>,
        inHeader: true
    },
    {
        url: "/blog/:name_in_url",
        component: <LayoutOld><Blog/></LayoutOld>,
    },
    {
        name: "FAQ",
        url: "/faq",
        component: <LayoutOld><FAQ/></LayoutOld>,
        inHeader: true
    },
    {
        name: "Галерея",
        url: "/gallery",
        component: <LayoutOld><Gallery/></LayoutOld>,
        inHeader: true
    },
    {
        name: "Партнеры",
        url: "/partners",
        component: <LayoutOld><Partners/></LayoutOld>,
        inHeader: true,
    }
]

export default RouteList;

