import {
  useCallback,
  useEffect,
  useState,
  useRef, memo
} from "react";
import "./multiRangeSlider.css";
import useDebounce from "../useDebounce";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MultiRangeSlider = memo(({title, min, max, onChange}) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => {
      const val = Math.round(((value - min) / (max - min)) * 100)
      if (val < 0) {
        return 0
      }
      if (val > 100) {
        return 100
      }
      return val
    },
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  const debouncedMinVal = useDebounce(minVal, 500);
  const debouncedMaxVal = useDebounce(maxVal, 500);


  useEffect(() => {
    onChange({min: debouncedMinVal, max: debouncedMaxVal})
  }, [debouncedMinVal, debouncedMaxVal, onChange]);

  return (
    <>
      <h4 className="pt-8 underline text-lg mb-4">{title}</h4>

      <div className="container">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          ref={minValRef}
          onChange={(event) => {
            const value = Math.min(+event.target.value, maxVal - 1);
            setMinVal(value);
            event.target.value = value.toString();
          }}
          className={classNames("thumb thumb--zindex-3", minVal > max - 100 ? "thumb--zindex-5" : '')}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          ref={maxValRef}
          onChange={(event) => {
            const value = Math.max(+event.target.value, minVal + 1);
            setMaxVal(value);
            event.target.value = value.toString();
          }}
          className="thumb thumb--zindex-4"
        />

        <div className="slider">
          <div className="slider__track"></div>
          <div ref={range} className="slider__range"></div>
          <div className="slider__left-value">{minVal}</div>
          <div className="slider__right-value">{maxVal}</div>
        </div>
      </div>
    </>
  );
}, (prevProps, nextProps) => {
  return prevProps.min === nextProps.min && prevProps.max === nextProps.max && prevProps.title === nextProps.title
});

export default MultiRangeSlider;