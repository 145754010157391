import React from 'react';

export default function LayoutOld({children}) {
  return (
    <main className="-mt-24 sm:-mt-20 min-h-screen">
      <div style={{paddingTop: '179px'}}>
        {children}
      </div>
    </main>
  )
}