import React from "react";
import CustomButton from "../../../../components/simple/custom-button";
import "./style.css";
import {animate} from "../../../../animation/Animation";

const FileService = () => {
    const onClickBtnHandle = () => {
        window.open(window.location.origin + "/file-service", '_self');
    }

    return (
        <div className="main-page-file-service">
            <div ref={(ref) => animate(ref, "zoomIn")}
                 className="main-page-file-service-header">Занимаетесь чип тюнингом?</div>
            <div className="main-page-file-service-article">
                <div  ref={(ref) => animate(ref, "zoomIn")}>
                    переходите на сайт нашего файл-сервиса и регистрируйтесь для получения персонального предложения
                </div>
            </div>
            <CustomButton onClick={onClickBtnHandle} btnRef={(ref) => animate(ref, "zoomIn")}>Файл-сервис</CustomButton>
        </div>
    )
}

export default FileService;