import React from "react";
import "./style.css";
import CustomButton from "../../components/simple/custom-button";

const FileService = () => {
    const onClickBtnHandle = () => {
        window.open('https://motorcar-tuning.shop/', '_blank');
    }

    return (
        <div className="file-service-page">
            <div className="file-service-container">
                <div className="file-service-header">Файл-сервис</div>
                <div className="file-service-content">
                    Представляем файл сервис проверенных решений по отключению систем экологии и других модификаций,
                    вносимых в прошивку электронных блоков управления:
                    <ul>
                        <li>легковых автомобилей</li>

                        <li>грузовых автомобилей</li>

                        <li>спецтехники</li>

                        <li>автобусов</li>

                        <li>мотоциклов, квадроциклов, скутеров, снегоходов</li>
                    </ul>

                    На нашем портале Вы сможете быстро и удобно купить качественный файл прошивку электронного
                    блока управления (ЭБУ) с нужными Вам отключениями или модификациями.
                    Все решения протестированы, корректны и безопасны. Файлы обрабатываются
                    в реальном времени нашими калибровщиками с использованием специального
                    лицензионного программного обеспечения либо автоматически с дополнительным ручным контролем.
                </div>
                <div className="file-service-btn-block">
                    <CustomButton onClick={onClickBtnHandle}>Файл-сервис</CustomButton>
                </div>
            </div>
        </div>
    )
}

export default FileService;