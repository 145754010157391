import React, {useEffect, useState} from "react";
import "./style.css";
import CustomButton from "../../components/simple/custom-button";
import Blog from "./components/blog";
import Pagination from "../../components/simple/pagination";
import CustomInput from "../../components/simple/custom-input";
import cfg from "../../config/Config";
import CustomLoader from "../../components/common/custom-loader";

const Blogs = () => {
    const [posts, setPosts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const url = cfg.backendUrl + "post";
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                setPosts(response);
                setIsLoaded(true);
            });
    }, []);


    return (
        <div className="blog">
            {isLoaded ?
            <div className="blog-container">
                <div className="blogs-header">Найти статью по вкусу</div>
                <div className="blog-search-container">
                    <CustomInput placeholder={"Напишите что-нибудь"}/>
                    <CustomButton size={"small"}>Поиск</CustomButton>
                </div>
                <div className="blog-blogs-container">
                    {posts.map((post) =>
                        <Blog key={post.name_in_url} data={post}/>
                    )}
                </div>
                <Pagination/>
            </div> : <CustomLoader/>}
        </div>
    )
}

export default Blogs