import React, {useEffect, useState} from "react";
import CustomButton from "../../../../components/simple/custom-button";
import {animate} from "../../../../animation/Animation";
import "./style.css";

const AboutUs = () => {
    const handleOnClickBtn = () => {
        window.open(window.location.origin + "/equipment", '_self');
    };

    const bgRef = React.createRef();

    const [imgLoaded, setImgLoaded] = useState(false);

    const getBgUrl = (el) => {
        var bg;
        if (el.currentStyle) { // IE
            bg = el.currentStyle.backgroundImage;
        } else if (document.defaultView && document.defaultView.getComputedStyle) { // Firefox
            bg = document.defaultView.getComputedStyle(el, "").backgroundImage;
        } else { // try and get inline style
            bg = el.style.backgroundImage;
        }
        return bg.replace(/url\(['"]?(.*?)['"]?\)/i, "$1");
    }

    useEffect(() => {
        const current = bgRef.current;
        const image = document.createElement('img');
        image.src = getBgUrl(bgRef.current);
        image.onload = function () {
            setImgLoaded(true);
            animate(current, "fadeInLeft", true, 2)
        };
    }, [bgRef]);

    return (
        <div className='about-us'>
            <div className='about-us-container'>
                <div style={{opacity: 0}}  ref={bgRef} className='about-us-img'/>
                <div className='about-us-info'>
                    <div style={{opacity: 0}} ref={(ref) => imgLoaded && animate(ref, "fadeInRightBig")} className='about-us-question'>Кто мы?</div>
                    <div style={{opacity: 0}}  ref={(ref) => imgLoaded && animate(ref, "fadeInRightBig")} className='about-us-who-we-are'>
                        <div>Тюнинг-ателье и команда профессионалов,</div>
                        <div>открываем новые возможности автомобилей.</div>
                        <div>Официальный дистрибьютор компании MAGICMOTORSPORT.</div>
                        <div>Официальный партнер ALIENTECH.</div>
                        <div>Предоставляем следующие услуги:</div>
                    </div>

                    <div style={{opacity: 0}}  ref={(ref) => imgLoaded && animate(ref, "fadeInRightBig")} className='about-us-our-capabilities'>
                        <div>1. Чип тюнинг</div>
                        <div>моторов и АКПП</div>
                    </div>

                    <div style={{opacity: 0}}  ref={(ref) => imgLoaded && animate(ref, "fadeInRightBig")} className='about-us-our-capabilities'>
                        <div>2. Разработка</div>
                        <div>тюнинг файлов</div>
                    </div>

                    <div style={{opacity: 0}}  ref={(ref) => imgLoaded && animate(ref, "fadeInRightBig")} className='about-us-our-capabilities'>
                        <div>3. Продажа</div>
                        <div>оборудования</div>
                    </div>

                    <div style={{opacity: 0}}  ref={(ref) => imgLoaded && animate(ref, "fadeInRightBig")} className='about-us-additional-info'>
                        <div>И многое другое!</div>
                    </div>

                    <CustomButton style={{opacity: 0}}  onClick={handleOnClickBtn} btnRef={(ref) => imgLoaded && animate(ref, "zoomIn")}>Перейти в каталог</CustomButton>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;
