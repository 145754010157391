import React from "react";
import "./style.css"

const Pagination = (props) => {
    return (
        <div className="custom-pagination">
            <div className="pagination-vector">
                <div>&lt;</div>
                <div className="pagination-direction">Предыдущая</div>
            </div>
            <div className="pagination-pages">
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
                <div>5</div>
            </div>
            <div className="pagination-vector">
                <div className="pagination-direction">Следущая</div>
                <div>&gt;</div>
            </div>
        </div>
    )
}

export default Pagination;