import {atom, selector} from "recoil";

export const initPriceMinMaxAtom = atom({
  key: "initPriceMinMaxState",
  default: {
    initMinPrice: 0,
    initMaxPrice: 0
  },
});

export const currentPriceMinMaxAtom = atom({
  key: "currentPriceMinMaxState",
  default: {
    minPrice: 0,
    maxPrice: 0,
  },
});


export const initPriceMinMaxSelector = selector({
  key: "initPriceMinMaxSelector",
  get: ({get}) => {
    return get(initPriceMinMaxAtom)
  }
});

export const currentPriceMinMaxSelector = selector({
  key: "currentPriceMinMaxSelector",
  get: ({get}) => {
    return get(currentPriceMinMaxAtom)
  }
});