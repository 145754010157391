import React from "react";
import './style.css';
import AboutUs from "./components/about-us";
import Tuning from "./components/tuning";
import FileService from "./components/file-service";

const Main = () => {
    return (
        <div className='main-page'>
            <AboutUs/>
            <Tuning/>
            <FileService/>
        </div>
    )
}

export default Main;