import React from "react";
import "./style.css"

const CustomInput = (props) => {
    const {className = ''} = props
    let classs = `${className} custom-input`;
    if (props.hasError) {
        classs +=" error";
    }
    if (props.alignCenterPlaceholder) {
        classs += " custom-input-placeholder-align-center";
    }

    return (
        <input value={props.value} onChange={props.onChange} className={classs} placeholder={props.placeholder}>{props.children}</input>
    )
}

export default CustomInput;