import React, {useEffect, useState} from "react";
import "./style.css";
import cfg from "../../config/Config";
import CustomLoader from "../../components/common/custom-loader";
import Partner from "./partner";

const Partners = () => {
    const [partners, setPartners] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const url = cfg.backendUrl + "partner";
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                setPartners(response);
                setIsLoaded(true);
            });
    }, []);

    return (
        <div className="partners-page">
            {isLoaded ?
                <div className="partners-container">
                    <div className="partners-header">Партнеры</div>
                    <div className="partners-content">
                        <div className="partners-container">
                            {partners.map((partner) => <Partner key={partner.name}
                                header={partner.name} article={partner.article}/>)}
                        </div>
                    </div>
                </div> : <CustomLoader/>}
        </div>
    )
}

export default Partners;