import React, {useCallback, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import cfg from "../../../../config/Config";
import CustomLoader from "../../../../components/common/custom-loader";
import {useRecoilState, useRecoilValue} from "recoil";
import {shoppingCartAtom} from "../../../recoil/atoms/shoppingCart";
import {
  currentPriceMinMaxAtom,
  currentPriceMinMaxSelector,
  initPriceMinMaxAtom,
} from "../../../recoil/atoms/priceMinMax";
import {ArrowDownIcon, ArrowUpIcon} from "@heroicons/react/outline";

const sortFields = [
  {
    field: 'priority',
    title: 'Priority'
  },
  {
    field: 'title',
    title: 'Title'
  },
  {
    field: 'price',
    title: 'Price'
  },
]

export default function ItemList() {
  const {id} = useParams();

  const {minPrice, maxPrice} = useRecoilValue(currentPriceMinMaxSelector);
  const [, setInitMinMaxPrice] = useRecoilState(initPriceMinMaxAtom);
  const [, setCurrentMinMaxPrice] = useRecoilState(currentPriceMinMaxAtom);

  const [categoryChanged, setCategoryChanged] = useState(true);
  const [dataUrl, setDataUrl] = useState(`${cfg.backendUrl}shop/products`);

  const getData = useCallback(
    (url) => {
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(response => {
          setModels(response.items);
          const {minPrice, maxPrice} = response.info
          setInitMinMaxPrice({
            initMinPrice: minPrice,
            initMaxPrice: maxPrice
          });
          if (categoryChanged) {
            setCurrentMinMaxPrice({
              minPrice,
              maxPrice
            })
            setCategoryChanged(false)
          }
          setIsLoaded(true);
        });
    },
    [categoryChanged, setInitMinMaxPrice, setCurrentMinMaxPrice],
  );


  useEffect(() => {
    getData(dataUrl)
  }, [dataUrl, getData]);

  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');

  const [sortBy, setSortBy] = useState(sortFields[0].field);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    setCategoryChanged(true)
    setSearchText('')
    setSearch('')
  }, [id]);

  useEffect(() => {
    const query = `search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&sort=[${sortBy},${sortDirection}]`
    setDataUrl(id
      ? `${cfg.backendUrl}shop/products/category/${id}?${query}`
      : `${cfg.backendUrl}shop/products?${query}`)
  }, [id, minPrice, maxPrice, sortBy, sortDirection, search])

  const [models, setModels] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [, setShoppingCart] = useRecoilState(shoppingCartAtom);

  if (!isLoaded) {
    return (<CustomLoader/>)
  }

  const addToCart = (model, e) => {
    e.stopPropagation()
    e.preventDefault()
    setShoppingCart((oldList) => {
      const index = oldList.findIndex(el => el.id === model.id)
      if (index === -1) {
        return [
          ...oldList,
          {
            id: model.id,
            count: 1,
            product: model,
          }
        ]
      }
      const el = oldList[index]
      return [...oldList.slice(0, index), {
        ...el,
        count: el.count + 1,
      }, ...oldList.slice(index + 1)];
    })
  }

  return (
    <div className="bg-white">

      <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:max-w-7xl lg:px-8 border-b border-gray-200">
        <div className="grid grid-cols-2 gap-y-10 sm:grid-cols-3 gap-x-6 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">

          <div className="w-full overflow-hidden aspect-auto">
            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5">
              <label htmlFor="sort-by-field" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Sort by:
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="sort-by-field"
                  name="sort-by-field"
                  autoComplete="sort-by-field"
                  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) => {
                    setSortBy(e.target.value);
                  }}
                >
                  {sortFields.map(({field, title}) => <option key={field} value={field}>{title}</option>)}
                </select>
              </div>

              {sortDirection === 'asc' &&
                <ArrowDownIcon className="h-4 w-4 mt-3 cursor-pointer" onClick={() => setSortDirection('desc')}/>}
              {sortDirection === 'desc' &&
                <ArrowUpIcon className="h-4 w-4 mt-3 cursor-pointer" onClick={() => setSortDirection('asc')}/>}

            </div>
          </div>

          <div/>

          <div className="w-full overflow-hidden aspect-auto">
            <div className="flex mt-1 sm:mt-0 sm:col-span-2 pt-5">
              <div className="max-w-lg flex rounded-md shadow-sm text-right">
                <input
                  type="text"
                  autoComplete="search"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                  className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                />
              </div>

              <button
                type="submit"
                className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setSearch(searchText)}
              >
                Search
              </button>

            </div>
          </div>

        </div>
      </div>

      <div className="max-w-2xl mx-auto py-6 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        {/*<h2 className="underline text-lg mb-10">Товары</h2>*/}

        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {models.map(product => ({
            ...product,
            image: `${cfg.backendImageUrl}product_img/${product.images[0]?.url || 'noimg.jpg'}`
          })).map(product => (
            <Link key={product.id} to={`/product/name/${product.name_in_url}`} className="group">
              <div
                className="w-full bg-gray-200 rounded-lg overflow-hidden aspect-auto">
                <img
                  src={product.image}
                  alt={product.title}
                  className="w-full h-full object-center object-cover group-hover:opacity-75"
                />
              </div>
              <h3 className="mt-4 text-center text-sm text-gray-600">{product.title}</h3>
              <p className="mt-1 text-center text-lg font-medium text-gray-900">€{product.price}</p>
              <p className="mt-1 text-center">
                <button
                  onClick={(e) => addToCart(product, e)}
                  className="justify-center py-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200">
                  Add to cart
                </button>
              </p>
            </Link>
          ))}
          {models.length === 0 && 'Товаров не найдено'}
        </div>
      </div>
    </div>
  )
}