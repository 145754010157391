import React from "react";
import "./style.css"

const CustomSelect= (props) => {
    return (
        <select value={props.value} onChange={props.onChange} className="custom-select" placeholder={props.placeholder}>
            {props.children}
        </select>
    )
}

export default CustomSelect;