import React from "react";
import "./style.css";

const Partner = (props) => {
    return (
        <div className="partner">
            <div className="partner-container">
                <div className="partner-header">{props.header}</div>
                <div dangerouslySetInnerHTML={{__html: props.article}}
                     className="partner-article"/>
            </div>
        </div>
    )
}

export default Partner;