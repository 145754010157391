import React, {useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {shoppingCartAtom, shoppingCartSelector} from "../../recoil/atoms/shoppingCart";
import cfg from "../../../config/Config";
import {useHistory} from "react-router-dom";
import CustomLoader from "../../../components/common/custom-loader";

const defaultErrors = {
  name: false,
  phone: false,
  email: false,
  address: false,
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function OrderForm() {
  const {shoppingCart} = useRecoilValue(shoppingCartSelector);
  const [, setShoppingCart] = useRecoilState(shoppingCartAtom);
  const history = useHistory()

  const [client, setClient] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
  });

  const [clientErrors, setClientErrors] = useState(defaultErrors);
  const [isLoaded, setIsLoaded] = useState(true);

  const setClientKey = (e, key) => {
    setClient({
      ...client,
      [key]: e.target.value,
    })
  }

  const hasError = () => {
    const errors = {
      ...defaultErrors
    }
    if (client.name.length === 0) {
      errors.name = true
    }
    if (client.phone.length === 0) {
      errors.phone = true
    }
    if (client.email.length === 0) {
      errors.email = true
    }
    if (client.address.length === 0) {
      errors.address = true
    }
    setClientErrors(errors)
    return Object.values(errors).reduce((acc, error) => acc || error, false)
  }

  const saveOrder = () => {
    if (hasError()) {
      return
    }
    setIsLoaded(false)
    const url = `${cfg.backendUrl}shop/orders`
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        client,
        items: shoppingCart.map(({id: product_id, count: amount}) => ({product_id, amount}))
      })
    }).then(response => response.json())
      .then(response => {
        if (response.statusCode === 500) {
          return
        }
        setShoppingCart([])
        history.push('/equipment')
      })
      .catch(e => console.error('!@# OrderForm.js:():53 ', e))
      .finally(() => setIsLoaded(true));
  }

  if (!isLoaded) {
    return (<CustomLoader/>)
  }

  return (
    <>
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Персональная иинформация</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Введите контактные данные для связи с вами</p>
        </div>
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Имя
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="name"
                value={client.name}
                onChange={(e) => setClientKey(e, 'name')}
                autoComplete="name"
                className={classNames('max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm rounded-md', clientErrors.name ? 'border-red-600' : 'border-gray-300')}
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Телефон
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="tel"
                value={client.phone}
                onChange={(e) => setClientKey(e, 'phone')}
                autoComplete="phone"
                className={classNames('max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm rounded-md', clientErrors.phone ? 'border-red-600' : 'border-gray-300')}
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Email
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                value={client.email}
                onChange={(e) => setClientKey(e, 'email')}
                type="email"
                autoComplete="email"
                className={classNames('max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm rounded-md', clientErrors.email ? 'border-red-600' : 'border-gray-300')}
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Адрес
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                value={client.address}
                onChange={(e) => setClientKey(e, 'address')}
                type="text"
                autoComplete="address"
                className={classNames('max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm rounded-md', clientErrors.address ? 'border-red-600' : 'border-gray-300')}
              />
            </div>
          </div>

        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={saveOrder}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Оформить
          </button>
        </div>
      </div>
    </>
  )
}