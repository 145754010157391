import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import RouteList from "../../../route/RouteList";
import './style.css'
import ShoppingCartHeader from "../../../tw/common/ShoppingCartHeader";

// const phones = [
//   {
//     id: '+380635843164',
//     city: 'Днепр',
//     phone: '+38 (063) 584 31 64',
//   },
//   {
//     id: '+380994155828',
//     city: 'Днепр',
//     phone: '+38 (099) 415 58 28',
//   },
//   {
//     id: '+380667352550',
//     city: 'Киев',
//     phone: '+38 (066) 735 25 50',
//   },
//   {
//     id: '+380997537185',
//     city: 'Мукачево',
//     phone: '+38 (099) 753 71 85',
//   },
//   {
//     id: '+380668834117',
//     city: 'Полтава',
//     phone: '+38 (066) 883 41 17',
//   },
//   // {
//   //   id: '+380994155828',
//   //   city: 'Славянск',
//   //   phone: '+38 (099) 415 58 28',
//   // },
//   {
//     id: '+380970733495',
//     city: 'Харьков',
//     phone: '+38 (097) 073 34 95',
//   },
//   // {
//   //   id: '',
//   //   city: '',
//   //   phone: '',
//   // },
// ].sort((a, b) => {
//   if (a.city < b.city) {
//     return -1;
//   }
//   if (a.city > b.city) {
//     return 1;
//   }
//   return 0;
// })

const Header = () => {
  const [selectedLink, setSelectedLink] = useState();
  const [burgerMenu, setBurgerMenu] = useState(false);

  useEffect(() => {
    setSelectedLink(window.location.pathname);
  }, [selectedLink])


  const isSelectedLink = (link) => {
    if (link === "/" && (selectedLink === "" || selectedLink === "/")) {
      return true;
    }

    return selectedLink && selectedLink.toString().startsWith(link) && link !== "/";
  }

  const linkOnClickHandle = (link) => {
    setBurgerMenu(false);
    setSelectedLink(link);

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const burgerBtnClickHandle = () => {
    setBurgerMenu(true);
  }

  const burgerCloseClickHandle = () => {
    setBurgerMenu(false);
  }

  const logoOnClick = () => {
    setBurgerMenu(false);
    setSelectedLink("/");
    window.open("/", "_self");
  }

  return (
    <div className="header">
      <div className="header-content">
        <img onClick={logoOnClick} alt="main-logo" className="main-logo" src="/LOGO-02.svg" height={45}
             width={80}/>
        <div className="header-content__phones">
          motorcar tuning<br/>
          официальный дистрибьютор MAGICMOTORSPORT<br/>
          официальный партнер ALIENTECH
        </div>
        <div className="header-link-container">
          {createHeader(linkOnClickHandle, isSelectedLink)}
        </div>

        <ShoppingCartHeader/>

        <div onClick={burgerBtnClickHandle} className="header-burger">
          <img alt='e' width='36' height='36' src='/icons/burger-icon.svg'/>
        </div>
      </div>

      <div style={!burgerMenu ? {display: "none"} : null} className="header-burger-container">
        <div className="header-burger-content">
          <div className="header-burger-img-container">
            <img onClick={logoOnClick} alt="main-logo" className="main-logo" src="/LOGO-02.svg"
                 height={48} width={80}/>
            <img alt="c" onClick={burgerCloseClickHandle} className="burger-close-icon"
                 src="/icons/close-icon.svg" height={36} width={36}/>
          </div>
          {createHeader(linkOnClickHandle, isSelectedLink)}
        </div>
        {/*<div className="header-burger-content__phones">*/}
        {/*  {phones.map(phone => <a key={phone.id} href={`tel:${phone.id}`} className="header-burger-content__phone"*/}
        {/*                          title={phone.city}>*/}
        {/*    <span className="header-burger-content__phone-city">{phone.city}:</span> {phone.phone}*/}
        {/*  </a>)}*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

const createHeader = (linkOnClickHandle, isSelectedLink) => {
  return <>
    {RouteList.map((route) =>
      route.inHeader ? <div key={route.url} onClick={() => linkOnClickHandle(route.url)}
                            className={isSelectedLink(route.url) ? "header-li-selected" : "header-li"}>
        {route.url === "/" || route.url === "" ?
          <a className={"header-link"} href={route.url}>{route.name}</a> :
          <Link className={"header-link"} to={route.url}>{route.name}</Link>
        }
      </div> : null
    )}
  </>;
}

export default Header;
