import React, {useRef, useState} from "react";
import "./style.css"
import CustomButton from "../../../../components/simple/custom-button";
import RequestModal from "../../../../components/common/request-modal";

const Price = (props) => {
    const [expanded, setExpanded] = useState(false);
    const expandedRef = useRef(null);

    const [openRequestModal, setOpenRequestModal] = useState(false);

    const closeModalHandle = () => {
        setOpenRequestModal(false);
    }

    const expandHandle = () => {
        setExpanded(!expanded);
        const content = expandedRef.current;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }

    return (
        <div className="price">
            <div onClick={expandHandle} className="price-container">
                <div className="price-name">{props.data.name}</div>
                <div className="price-price">{props.data.price}</div>
                <div className="price-action">{expanded ? "-" : "+"}</div>
            </div>

            <div ref={expandedRef} className="price-expanded-container">
                <div className="price-expanded-article">{props.data.article}</div>
                <div className="price-expanded-btn-container">
                    <CustomButton onClick={() => setOpenRequestModal(true)} size={"small"}>Оставить заявку</CustomButton>
                    <RequestModal type={"price"} name={props.data.name} closeModalHandle={closeModalHandle} open={openRequestModal}/>
                </div>
            </div>
        </div>
    )
}

export default Price;