import React from "react";
import Loader from "react-loader-spinner";
import './style.css';

const CustomLoader = () => {
    return (
        <Loader
            className={"loader"}
            type="Grid"
            color="#FF3031"
            height={50}
            width={50}
        />
    )
}

export default CustomLoader;