import React from "react";
import "./style.css";
import {animate} from "../../../../animation/Animation";

const Tuning = () => {
    return (
        <div className="tuning">
            <div ref={(ref) => animate(ref, "zoomIn")} className="tuning-header">Прирост после тюнинга</div>
            <div ref={(ref) => animate(ref, "zoomIn")} className="tuning-article">
                Укажите характеристики автомобиля и получите подробный отчет о приросте показателей после тюнинга
            </div>
            <iframe className="tuning-iframe" title="tuning"
                    src="https://tuning-shop.com/iframe/iframe.php?user=497"
                    width="90%" scrolling="no"
                    height="auto"
                    style={{border: "0"}}
                    frameBorder="0" id="iFrameResizer0"/>
        </div>
    )
}

export default Tuning;