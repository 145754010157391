import React, {useState} from "react";
import "./style.css";
import CustomInput from "../../simple/custom-input";
import CustomButton from "../../simple/custom-button";
import CustomSelect from "../../simple/custom-select";
import cfg from "../../../config/Config";
import CustomLoader from "../custom-loader";

const RequestModal = (props) => {
    const open = props.open;
    const type = props.type;

    const [fullName, setFullName] = useState("");
    const [fullNameError, setFullNameError] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [mobilePhone, setMobilePhone] = useState("");
    const [mobilePhoneError, setMobilePhoneError] = useState(false);
    const [city, setCity] = useState(1);

    const [globalError, setGlobalError] = useState('');

    const [completed, setCompleted] = useState(false);
    const [expectAnswer, setExpectAnswer] = useState(false);

    const handleSendRequest = (e) => {
        e.preventDefault()

        setExpectAnswer(true);
        setGlobalError('')
        if (fullName.length === 0) {
            setFullNameError(true);
        }
        if (email.length === 0) {
            setEmailError(true);
        }
        if (mobilePhone.length === 0) {
            setMobilePhoneError(true);
        }
        if (fullName.length === 0 || email.length === 0 || mobilePhone.length === 0) {
            setExpectAnswer(false);
            return
        }
        const url = cfg.backendUrl + "bot/request/" + type;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify({
                name: props.name,
                name_in_url: props.name_in_url,
                city,
                fullName,
                email,
                mobilePhone,
            })
        }).then(response => response.json())
            .then(response => {
                // Validation error
                if (response.statusCode && response.statusCode === 400) {
                    setGlobalError(response.message[0])
                    setExpectAnswer(false);
                    return
                }
                setCompleted(true);
                setExpectAnswer(false);
            })
    }

    const onCloseModalHandle = () => {
        props.closeModalHandle();
        setCompleted(false);
        setCity(1);
        setEmail("");
        setMobilePhone("");
        setFullName("");

        setGlobalError('')
        setFullNameError(false)
        setEmailError(false)
        setMobilePhoneError(false)
    }

    return (
        <div>
            {open ?
                (<div className="request-modal-window">
                    <div className="request-modal">
                        <div className="request-modal-container">
                            <img className="request-modal-logo" alt="main-logo" src={"/LOGO-01.png"} width={120}
                                 height={78}/>
                            <img onClick={onCloseModalHandle} alt="c" className="request-modal-close-icon"
                                 src={"/icons/close-icon-black.svg"} height={36} width={36}/>

                            <div className="request-modal-header">
                                {completed ? "Ваша заявка отправлена!" : "Запишитесь на консультацию"}
                            </div>
                            <div className="request-modal-article">
                                {completed ? "Наш менеджер свяжется с вами в ближайшее время. " +
                                    "А пока вы можете отправить еще одну заявку или продолжить просмор сайта" :
                                    "оставьте пожалуйста свои данные и наш менеджер с Вами свяжется"
                                }
                            </div>

                            {expectAnswer ?
                                <CustomLoader/> :

                                !completed ?
                                <div className="request-modal-input-container">

                                    {globalError.length > 0 &&
                                      <div className="error-block">
                                          {globalError}
                                      </div>
                                    }

                                    <CustomSelect onChange={(event) => setCity(event.target.value)} value={city}>
                                        <option value={4}>Днепр</option>
                                        <option value={5}>Полтава</option>
                                        <option value={1}>Харьков</option>
                                    </CustomSelect>
                                    <CustomInput value={fullName}
                                                 hasError={fullNameError}
                                                 onChange={(event) => {
                                                     const value = event.target.value
                                                     if (fullNameError && value.length > 0) {
                                                         setFullNameError(false)
                                                     }
                                                     setFullName(value)
                                                 }
                                                 }
                                                 alignCenterPlaceholder={true}
                                                 placeholder="Имя"/>
                                    <CustomInput value={email}
                                                 hasError={emailError}
                                                 onChange={(event) => {
                                                     const value = event.target.value
                                                     if (emailError && value.length > 0) {
                                                         setEmailError(false)
                                                     }
                                                     setEmail(value)
                                                 }
                                                 }
                                                 alignCenterPlaceholder={true}
                                                 placeholder="E-mail"/>
                                    <CustomInput value={mobilePhone}
                                                 hasError={mobilePhoneError}
                                                 onChange={(event) => {
                                                   const value = event.target.value
                                                   if (mobilePhoneError && value.length > 0) {
                                                     setMobilePhoneError(false)
                                                   }
                                                   setMobilePhone(value)
                                                 }
                                                 }
                                                 alignCenterPlaceholder={true}
                                                 placeholder="Телефон"/>
                                    <div className="request-modal-button-container">
                                        <CustomButton onClick={handleSendRequest} size="modal">Отправить</CustomButton>
                                    </div>
                                </div> : null}

                        </div>
                    </div>
                </div>)

                : null}
        </div>
    )
}

export default RequestModal;
