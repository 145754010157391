import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {Disclosure} from '@headlessui/react'
import cfg from "../../../config/Config";
import CustomLoader from "../../../components/common/custom-loader";
import MultiRangeSlider from "../../common/MultiRangeSlider/MultiRangeSlider";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentPriceMinMaxAtom, initPriceMinMaxSelector} from "../../recoil/atoms/priceMinMax";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Categories() {
  const {initMinPrice, initMaxPrice} = useRecoilValue(initPriceMinMaxSelector);
  const [, setCurrentMinMaxPrice] = useRecoilState(currentPriceMinMaxAtom);

  const [models, setModels] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const url = cfg.backendUrl + "shop/categories/tree";
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(response => {
        setModels(response);
        setIsLoaded(true);
      });
  }, []);

  if (!isLoaded) {
    return (<CustomLoader/>)
  }

  const onPriceRangeChange = ({min, max}) => {
    setCurrentMinMaxPrice({
      minPrice: min,
      maxPrice: max
    })
  }

  return (
    <div className="flex flex-col flex-grow pt-5 pb-4 bg-white overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <h3 className="underline text-lg mb-4">Категории</h3>
      </div>
      <div className="mt-5 flex-grow flex flex-col">
        <nav className="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
          {models.map((item) =>
            !item.items ? (
              <div key={item.id}>
                <NavLink
                  to={`/category/${item.id}`}
                  isActive={(match, location) => {
                    const parts = location.pathname.split('/')
                    if (parts[1] !== 'category') {
                      return false
                    }
                    return +parts[2] === item.id
                  }}
                  className={isActive => classNames(
                    isActive
                      ? 'bg-gray-100 text-gray-900'
                      : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group w-full flex items-center pl-7 pr-2 py-2 text-sm font-medium rounded-md'
                  )}
                >
                  {item.title}
                </NavLink>
              </div>
            ) : (
              <Disclosure as="div" key={item.id} className="space-y-1">
                {({open}) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        item.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                      )}
                    >
                      <svg
                        className={classNames(
                          open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                          'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                        )}
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
                      </svg>
                      {item.title}
                    </Disclosure.Button>
                    <Disclosure.Panel className="space-y-1">
                      {item.items.map((subItem) => (
                        <NavLink
                          key={subItem.id}
                          to={`/category/${subItem.id}`}
                          className="group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          {subItem.title}
                        </NavLink>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )
          )}
        </nav>
      </div>


      {isLoaded && (initMinPrice < initMaxPrice) &&
        <MultiRangeSlider
          title="Price"
          min={initMinPrice}
          max={initMaxPrice}
          onChange={onPriceRangeChange}
        />
      }

    </div>
  );
}
