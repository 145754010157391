import React, {useEffect, useState} from "react";
import cfg from "../../config/Config";
import {useParams} from "react-router-dom";
import "./style.css";
import CustomLoader from "../../components/common/custom-loader";
const baseImage = 'https://motorcar-tuning.com.ua/LOGO-01.png';

const Blog = () => {
    let {name_in_url} = useParams();
    const [blog, setBlog] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const url = cfg.backendUrl + "post/" + name_in_url;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                document.title = response.seo_title
                document.querySelectorAll('meta[name="description"]')[0].content = response.seo_description;
                document.querySelectorAll('meta[name="keywords"]')[0].content = response.seo_keywords;

                document.querySelectorAll('[property="og:image"]')[0].content = cfg.backendImageUrl + "post/" + response.image;
                document.querySelectorAll('[property="og:image:secure_url"]')[0].content = cfg.backendImageUrl + "post/" + response.image;
                setBlog(response);
                setIsLoaded(true);
            });
      return () => {
        document.querySelectorAll('[property="og:image"]')[0].content = baseImage;
        document.querySelectorAll('[property="og:image:secure_url"]')[0].content = baseImage;
      }
    }, [name_in_url]);

    return (
        <div className="blog-page">
            {isLoaded ? (
                <div className="blog-container">
                    <img src={cfg.backendImageUrl + "post/" + blog.image}
                         alt="equipment" className="blog-image"/>
                    <div className="blog-header">
                        {blog.name}
                    </div>
                    <div className="blog-article">
                        <div dangerouslySetInnerHTML={{__html: blog.article}}/>
                    </div>
                    {blog.additionalPostInfo && blog.additionalPostInfo.length ?
                        <div>
                            {blog.additionalPostInfo.map((child, index) =>
                                <div key={index}>
                                    <div className="blog-article">
                                        <div dangerouslySetInnerHTML={{__html: child.article}}/>
                                    </div>
                                    <img src={cfg.backendImageUrl + "post/" + child.image}
                                         alt="equipment" className="blog-image"/>
                                </div>
                            )}
                        </div>
                        : null}
                </div>) : <CustomLoader/>}
        </div>
    )
}

export default Blog;