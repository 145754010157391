import React from 'react';

export default function LayoutCol3R({left, right}) {
  return (
    <main className="-mt-24 pb-8 sm:-mt-20 min-h-screen">
      <div className="mx-auto px-4 sm:px-8 lg:px-12 pt-56">
        {/* Main 3 column grid */}
        <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-4 lg:gap-8">
          {/* Left column */}
          <div className="grid grid-cols-1 gap-4 lg:col-span-1">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="p-6">

                {left}

              </div>
            </div>
          </div>

          {/* Right column */}
          <div className="grid grid-cols-1 gap-4 lg:col-span-3">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="px-6">

                {right}

              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}