import React from "react";
import './style.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="contact-info">Контактная информация</div>
                <div className="contact-info-container">
                    <div className="footer-logo-container">
                        <a className="footer-icon-link" href="https://www.instagram.com/motorcar_tuning" target="_blank" rel="noreferrer">
                            <img alt='i' src="/icons/instagram.svg" height='32' width='32'/>
                        </a>
                        <a className="footer-icon-link" href="https://www.facebook.com/motorcar.tuning/" target="_blank" rel="noreferrer">
                            <img alt='f' src="/icons/facebook.svg" height='32' width='32'/>
                        </a>
                        <a className="footer-icon-link" href="https://www.youtube.com/channel/UCIJax_AdbOaNm1v2tMoI9Lw" target="_blank" rel="noreferrer">
                            <img alt='y' src="/icons/youtube.svg" height='32' width='32'/>
                        </a>
                    </div>
                    <div className="footer-partners-container">
                        <div>
                            <div className="footer-partner-name">Киев</div>
                            <div></div>
                            <div className="text-strong"><a href="tel:+380667352550">+38 (066) 735 25 50</a></div>
                            <div>Viber, Whatsapp</div>
                        </div>
                        <div>
                            <div className="footer-partner-name">Днепр</div>
                            <div>проспект Мануйловский, 73</div>
                            <div className="text-strong"><a href="tel:+380994155828">+38 (099) 415 58 28</a></div>
                            <div>Viber, Telegram, Whatsapp</div>
                        </div>
                        <div>
                            <div className="footer-partner-name">Белая Церковь</div>
                            <div>СТО VagSergiceBC</div>
                            <div>Ул. Киевская 86</div>
                            <div className="text-strong"><a href="tel:+380938725300">+38 (093) 872 53 00</a></div>
                            <div>Viber, Telegram, Whatsapp</div>
                        </div>
                        <div>
                            <div className="footer-partner-name">Николаев</div>
                            <div>ул. Мореходная, 1в/3</div>
                            <div className="text-strong"><a href="tel:+380992771733">+38 (099) 277 17 33</a></div>
                            <div>Viber, Telegram, Whatsapp</div>
                        </div>
                        <div>
                            <div className="footer-partner-name">Полтава</div>
                            <div>Киевское шосе, 33</div>
                            <div className="text-strong"><a href="tel:+380668834117">+38 (066) 883 41 17</a></div>
                            <div>Viber, Telegram, Whatsapp</div>
                        </div>
                        <div>
                            <div className="footer-partner-name">Харьков</div>
                            <div>пер. Людмилы Гурченко 3Д</div>
                            <div className="text-strong"><a href="tel:+380970733495">+38 (097) 073 34 95</a></div>
                            <div>Viber, Telegram, Whatsapp</div>
                        </div>
                        <div>
                            <div className="footer-partner-name">Сарни</div>
                            <div>вул. Ринкова, 28</div>
                            <div className="text-strong"><a href="tel:+380668085600">+38 (066) 808 56 00</a></div>
                            <div>Viber, Telegram, Whatsapp</div>
                        </div>
                        <div>
                            <div className="footer-partner-name">Житомир</div>
                            <div className="text-strong"><a href="tel:+380634289663">+38 (063) 428 96 63</a></div>
                            <div>Viber, Telegram, Whatsapp</div>
                        </div>
                        <div>
                            <div className="footer-partner-name">Шостка</div>
                            <div className="text-strong"><a href="tel:+380662696921">+38 (066) 269 69 21</a></div>
                            <div>Viber, Telegram, Whatsapp</div>
                        </div>
                    </div>
                    <img className="footer-icon-link-mms" alt='i' src="/icons/Logo_MMS.png" height='64' width='148'/>
                </div>
            </div>
            <div className="footer-line"/>
            <div className="footer-additional-info">
                <div className="footer-additional-info-element">
                    <div className='footer-imaged-container'>
                        <img alt='e' width='16' height='16' src='/icons/envelope-footer-icon.svg'/>
                        <div className='footer-imaged-text'>motorcar.tuning@gmail.com</div>
                    </div>
                    <div className="footer-copyright-content">
                        <div>ⓒ</div>
                        <div className="footer-imaged-text">mc-tuning.ua</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
