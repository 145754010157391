import React from "react";
import cfg from "../../../../config/Config";
import { useHistory } from "react-router-dom";
import "./style.css";


const Blog = (props) => {
    const history = useHistory();
    const onClickBlogHandle = () => {
        history.push("blog/" + props.data.name_in_url);
    }

    const date = new Date(props.data.date);

    const dateString = ('0' + date.getDate()).slice(-2) + '/'
        + ('0' + (date.getMonth()+1)).slice(-2) + '/'
        + date.getFullYear();

    const blogImage = cfg.backendImageUrl + "post/" + props.data.image;
    return (
        <div className="blog-blog-field">
            <img alt={"img"} onClick={() => onClickBlogHandle()}
                 className="blog-picture-container"
                 src={blogImage}>
            </img>
            <div onClick={() => onClickBlogHandle()} className="blog-picture-name">
                {props.data.name}
            </div>
            <div onClick={() => onClickBlogHandle()} className="blog-picture-date">
                {dateString}
            </div>
            <div onClick={() => onClickBlogHandle()} className="blog-picture-arrow-right">
                >
            </div>
        </div>
    )
}


export default Blog;